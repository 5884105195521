import { useEffect, useState } from "react";

export default function BigModeButton() {
    const [isOn, setOn] = useState(false);

    const toggleBigMode = () => setOn(!isOn);

    useEffect(() => {
        const classes = document.body.classList;
        if (isOn && !classes.contains('big-mode')) {
            classes.add('big-mode');
        } else if (!isOn && classes.contains('big-mode')) {
            classes.remove('big-mode');
        }
    }, [isOn]);

    return <button type="button" className="btn btn-link p-0" onClick={toggleBigMode}><i className={isOn ? "bi-arrows-angle-contract" : "bi-arrows-angle-expand"}></i></button>;
}