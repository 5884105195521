import { selectStoryItems } from "../redux/StorySlice";
import { useAppSelector } from "../redux/store";
import { StoryItem } from "../types/StoryItem";

import StoryElementCard from "./StoryElementCard";

export default function StoryElementsList() {
    const story = useAppSelector(selectStoryItems);
    const lastItemUUID = story.length === 0 ? '' : story[story.length - 1].uuid;

    if (story.length === 0) {
        return null;
    }

    const renderStory = (item: StoryItem) => <StoryElementCard key={item.uuid} story={item} current={lastItemUUID === item.uuid} />;

    return <div>{story.map(renderStory)}</div>
}
