import { StoryItem } from "../types/StoryItem";

import StoryImageBlock from "./StoryImageBlock";
import StoryStatusIndicator from "./StoryStatusIndicator";
import StoryTextBlock from "./StoryTextBlock";

export default function StoryElementCard({ story, current }: { story: StoryItem, current?: boolean }) {
    return (
        <div className="card mb-2">
            <StoryStatusIndicator story={story} />
            <div className="row g-0 story-element">
                <div className="col-4 story-image-column">
                    <StoryImageBlock story={story} />
                </div>
                <div className="col-8 story-text-column">
                    <div className="card-body">
                        <StoryTextBlock story={story} current={current} />
                    </div>
                </div>
            </div>
        </div>
    );
}
