import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Scenario, ScenarioName, makeScenario } from "../types/Scenario";
import { StoryItem, makeStoryItem } from "../types/StoryItem";
import { StoryOptions } from "../types/StoryOptions";
import { GeneratorStatus, StoryItemStatus } from "../types/GeneratorStatus";
import { localStorageJSON, localStorageJSONArray } from "../types/LocalStorageRepresentable";
import { RecentStory, eraseRecentStory, makeRecentStory, recentStoriesArray } from "../types/RecentStory";
import { OpenAIClient } from "../openai/OpenAIContext";

interface StoryState {
    scenario: Scenario | null;
    creation: string; // date and time;
    options: StoryOptions;
    items: Array<StoryItem>;
    recentStories: Array<RecentStory>;
}

const STORY_OPTIONS = localStorageJSON<StoryOptions>('storyOptions');
const RECENT_STORIES = localStorageJSONArray<RecentStory>('recentStories');

function makeInitialState(): StoryState {
    const defaultOptions: StoryOptions = {
        generateImages: true,
        generateSpeech: true,
        autoSpeak: true,
    };

    const options = STORY_OPTIONS.read() ?? defaultOptions;
    const recentStories = RECENT_STORIES.read() ?? [];

    return {
        scenario: null,
        creation: new Date().toISOString(),
        options,
        items: [],
        recentStories,
    };
}

const storySlice = createSlice({
    name: 'story',
    initialState: makeInitialState(),

    reducers: {
        
        startScenario: (state, action: PayloadAction<ScenarioName>) => {
            state.scenario = makeScenario(action.payload);
            state.creation = new Date().toISOString();
            state.items = [];
            OpenAIClient.shared.useApiKey(state.options.openAiApiKey);
        },

        updateOptions: (state, action: PayloadAction<StoryOptions>) => {
            const options = action.payload;
            state.options = options;
            STORY_OPTIONS.write(options);
        },

        updateStoryStatus: (state, action: PayloadAction<{ uuid: string, generator: 'content' | 'image' | 'speech', status: GeneratorStatus }>) => {
            const { uuid, generator, status } = action.payload;
            const items = [...state.items];
            const index = items.findIndex(next => next.uuid === uuid);
            if (index !== -1) {
                if (generator === 'content') {
                    const next: StoryItemStatus = {
                        ...items[index].status,
                        content: status,
                    }
                    items[index] = {...items[index], status: next };
                } else if (generator === 'image') {
                    const next: StoryItemStatus = {
                        ...items[index].status,
                        image: status,
                    }
                    items[index] = {...items[index], status: next };
                } else if (generator === 'speech') {
                    const next: StoryItemStatus = {
                        ...items[index].status,
                        speech: status,
                    }
                    items[index] = {...items[index], status: next };
                }
            }
            state.items = items;
        },

        pushUserInput: (state, action: PayloadAction<string>) => {
            const input = action.payload;
            const item = makeStoryItem(input);
            state.items = [...state.items, item];
        },

        retryLastUserInput: (state) => {
            const items = [...state.items];
            const userInput = items.length === 0 ? null : items[items.length - 1].userInput;
            if (!!userInput?.length) {
                items.splice(items.length - 1, 1);
                const item = makeStoryItem(userInput);
                state.items = [...items, item];
            }
        },

        updateStoryItem: (state, action: PayloadAction<StoryItem>) => {
            const item = action.payload;
            const items = [...state.items];
            const index = items.findIndex(next => next.uuid === item.uuid);
            if (index === -1) {
                items.push(item);
            } else {
                items[index] = item;
            }
            state.items = items;

            if (state.scenario) {
                const recentStory = makeRecentStory(state.scenario, state.creation, items);
                const recentStories = recentStoriesArray(recentStory, state.recentStories);
                state.recentStories = recentStories;
                RECENT_STORIES.write(recentStories);
            }
        },

        openRecentStory: (state, action: PayloadAction<RecentStory>) => {
            const story = action.payload;
            state.scenario = story.scenario;
            state.creation = story.creation;
            state.items = story.items;
            OpenAIClient.shared.useApiKey(state.options.openAiApiKey);
        },

        deleteRecentStory: (state, action: PayloadAction<RecentStory>) => {
            const story = action.payload;
            const recentStories = eraseRecentStory(story, state.recentStories);
            state.recentStories = recentStories;
            RECENT_STORIES.write(recentStories);
        },
    },
});

export default storySlice.reducer;
export const { startScenario, updateOptions, updateStoryStatus, pushUserInput, retryLastUserInput, updateStoryItem, openRecentStory, deleteRecentStory } = storySlice.actions;

export const selectScenario = (root: RootState) => root.story.scenario;
export const selectStoryOptions = (root: RootState) => root.story.options;
export const selectStoryItems = (root: RootState) => root.story.items;
export const selectRecentStories = (root: RootState) => root.story.recentStories;

export const selectCurrentStoryItem = createSelector(selectStoryItems, (story) => {
    if (story.length === 0) {
        return undefined;
    } else {
        return story[story.length - 1];
    }
});
