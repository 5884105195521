import OpenAI from "openai";
import { createContext, useContext } from "react";
import { StoryItem, storyItem } from "../types/StoryItem";
import { StoryOptions } from "../types/StoryOptions";
import { GeneratorStatus, UpdateGeneratorStatusFn } from "../types/GeneratorStatus";
import { Scenario } from "../types/Scenario";

interface StoryItemGenerationProps {
    storyItem: StoryItem;
    scenario: Scenario;
    story: Array<StoryItem>;
    options: StoryOptions;
    updateStoryItemStatus: (uuid: string, which: 'content' | 'image' | 'speech', status: GeneratorStatus) => void;
}

export class OpenAIClient {

    api: OpenAI;
    static shared = new OpenAIClient();

    constructor() {
        const apiKey = process.env.REACT_APP_OPENAI_KEY;
        if (!apiKey?.length) {
            throw new Error(`Missing OpenAI key!`);
        }
        
        console.log(`Using OpenAI API key ${apiKey}`);
        this.api = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
    }

    useApiKey(apiKey: string | undefined) {
        if (!!apiKey?.length && apiKey !== this.api.apiKey) {
            console.log(`Using OpenAI API key ${apiKey}`);
            this.api = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
        } else if (!apiKey?.length) {
            const defaultKey = process.env.REACT_APP_OPENAI_KEY;
            if (defaultKey !== this.api.apiKey) {
                console.log(`Using OpenAI API key ${defaultKey}`);
                this.api = new OpenAI({ apiKey: defaultKey, dangerouslyAllowBrowser: true });
            }
        }
    }

    async generate(props: StoryItemGenerationProps): Promise<StoryItem> {
        const openai = this.api;
        const uuid = props.storyItem.uuid;

        const updateContentStatus: UpdateGeneratorStatusFn = (status) => {
            props.updateStoryItemStatus(uuid, 'content', status);
        };

        const updateImageStatus: UpdateGeneratorStatusFn = (status) => {
            props.updateStoryItemStatus(uuid, 'image', status);
        };

        const updateSpeechStatus: UpdateGeneratorStatusFn = (status) => {
            props.updateStoryItemStatus(uuid, 'speech', status);
        };

        return await storyItem({
            ...props,
            openai,
            updateContentStatus,
            updateImageStatus,
            updateSpeechStatus,
        });
    }

}

export const OpenAIContext = createContext(OpenAIClient.shared);
OpenAIContext.displayName = 'OpenAIContext';

export const useOpenAI = () => useContext(OpenAIContext);
export const defaultOpenAI = () => OpenAIClient.shared;