import { Scenario } from "./Scenario";
import { StoryItem } from "./StoryItem";

export interface RecentStory {
    creation: string; // date and time
    scenario: Scenario;
    items: Array<StoryItem>;
}

export function makeRecentStory(scenario: Scenario, creation: string, items: Array<StoryItem>): RecentStory {
    return { creation, scenario, items };
}

export function recentStoriesArray(recentStory: RecentStory, array: Array<RecentStory>, maxCount: number = 20): Array<RecentStory> {
    const index = array.findIndex(next => next.scenario.name === recentStory.scenario.name && next.creation === recentStory.creation);
    const modifiedArray = [...array];
    if (index === -1) {
        modifiedArray.push(recentStory);
    } else {
        modifiedArray[index] = recentStory;
    }

    if (modifiedArray.length > maxCount) {
        return modifiedArray.slice(-maxCount);
    } else {
        return modifiedArray;
    }
}

export function eraseRecentStory(recentStory: RecentStory, array: Array<RecentStory>): Array<RecentStory> {
    return array.filter(next => next.scenario.name !== recentStory.scenario.name || next.creation !== recentStory.creation);
}
