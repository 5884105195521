import { Scenario } from "../types/Scenario";

const premise = `This is a text adventure game called "Valley Lodge" in which our player is trying to find 
their way to a small place called Valley Lodge in the year 1886. We are starting in Dickshooter (Idaho). 
Other characters that might appear are:

- Torgo, a man wearing ragged clothes and a hat. Torgo has two sets of eye brows and crooked knees.
- Steve, a squirrel.
- Richard Shooter, the mayor of Dickshooter.
- Pepsiman, a shady character involved in a conspiracy.
- Dianalee, an adventurous girl looking for the Orb of Destiny.
- Trevor Snodgrass, Dianalee's biology teacher, who sent Dianalee to look for the Orb of Destiny.
- Aldo, an Italian plumber with a moustache and a red baseball cap.
- Eagleman, an insurance salesman, with really big eyes.
- Crazy Bruce, a drunkard who sells liquor.
- Delbert, nobody has ever heard of him before.
- Debbie, a little ghost girl.
- Ecco, the god of dolphins and our nemesis.
- Gary, a cook who specializes in barbeque.
- A giant moth.`;

const scenario: Scenario = {
    name: 'Valley Lodge',
    description: `A wild-west adventure to find the elusive Valley Lodge.`,
    imageName: 'ValleyLodge.png',
    premise,
};

export default scenario;
