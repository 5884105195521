import { useCallback, useEffect, useRef, useState } from "react";
import { useOpenAI } from "../openai/OpenAIContext";
import { StoryItem, speech } from "../types/StoryItem";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { selectStoryOptions, updateStoryItem, updateStoryStatus } from "../redux/StorySlice";
import { localStorageStringArray } from "../types/LocalStorageRepresentable";

type Status = 'unavailable' | 'generating' | 'playing' | 'stopped';

export default function SpeakButton({ story }: { story: StoryItem }) {
    const [status, setStatus] = useState<Status>(story.speechURL?.length ? 'stopped' : 'unavailable');
    const audioRef = useRef<HTMLAudioElement>(null);
    const openai = useOpenAI();
    const dispatch = useAppDispatch();
    const options = useAppSelector(selectStoryOptions);

    const play = useCallback((givenURL?: string) => {
        const audio = audioRef.current;
        const url = typeof givenURL === 'string' ? givenURL : story.speechURL;

        if (!!audio && !!url?.length) {
            audio.src = url;
            audio.play()
                .then(() => {
                    setStatus('playing');
                })
                .catch((error) => {
                    setStatus('stopped');
                    console.error(error);
                });
        }
    }, [audioRef, story]);

    const stop = () => {
        setStatus('stopped');

        const audio = audioRef.current;
        if (audio) {
            audio.pause();
            audio.src = '';
        }
    };

    const generateSpeech = () => {
        if (!story.speechURL?.length && story.status.speech !== 'generating') {
            setStatus('generating');

            speech(openai.api, story)
                .then((item) => {
                    if (item) {
                        setStatus('stopped');
                        dispatch(updateStoryItem(item));
                        dispatch(updateStoryStatus({ uuid: story.uuid, generator: 'speech', status: 'completed' }));
                        play(item.speechURL ?? undefined);
                    }
                })
                .catch((error) => {
                    setStatus('stopped');
                    dispatch(updateStoryStatus({ uuid: story.uuid, generator: 'speech', status: 'failed' }));
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.addEventListener('ended', () => setStatus('stopped'));
        }
    }, [audioRef]);

    useEffect(() => {
        if (options.autoSpeak && !!story.speechURL?.length && !autoSpoken(story.uuid)) {
            play(story.speechURL);
        }
    }, [options, story, play]);

    return (
        <div className="text-end">
            {status === 'unavailable' && <GenerateTTSButton onClick={generateSpeech} />}
            {status === 'generating' && <GeneratingIndicator />}
            {status === 'stopped' && <PlayButton onClick={play} />}
            {status === 'playing' && <StopButton onClick={stop} />}
            <audio ref={audioRef} />
        </div>
    );
}

function GenerateTTSButton({ onClick }: { onClick: () => void }) {
    return <button type="button" className="btn btn-link" onClick={onClick}><i className="bi-play-fill" title="Generate TTS"></i></button>;
}

function GeneratingIndicator() {
    return <button type="button" className="btn btn-link" disabled><i className="bi-cloud-download" title="Generating TTS"></i></button>;
}

function PlayButton({ onClick }: { onClick: () => void }) {
    return <button type="button" className="btn btn-link" onClick={onClick}><i className="bi-play-fill" title="Play"></i></button>;
}

function StopButton({ onClick }: { onClick: () => void }) {
    return <button type="button" className="btn btn-link" onClick={onClick}><i className="bi-stop-fill" title="Stop"></i></button>;
}

const AUTO_SPOKEN_UUIDS = localStorageStringArray('autoSpokenUUIDs', { storage: sessionStorage });

function autoSpoken(uuid: string): boolean {
    const uuids = AUTO_SPOKEN_UUIDS.read() ?? [];
    const spoken = uuids.includes(uuid);

    if (!spoken) {
        AUTO_SPOKEN_UUIDS.write([...uuids, uuid]);
    }

    return spoken;
}