import { useState } from "react";
import { selectStoryOptions, updateOptions } from "../redux/StorySlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useOpenAI } from "../openai/OpenAIContext";

export default function OptionsPanel() {
    const dispatch = useAppDispatch();
    const openai = useOpenAI();
    const options = useAppSelector(selectStoryOptions);
    const [openAiApiKey, setOpenAiApiKey] = useState(options.openAiApiKey ?? "");
    const isOpenAiApiKeyChanged = openAiApiKey !== (options.openAiApiKey ?? "");
    
    const toggleGenerateImages = () => {
        dispatch(updateOptions({ ...options, generateImages: !options.generateImages }));
    };

    const toggleGenerateSpeech = () => {
        dispatch(updateOptions({ ...options, generateSpeech: !options.generateSpeech }));
    };

    const toggleAutoSpeak = () => {
        dispatch(updateOptions({ ...options, autoSpeak: !options.autoSpeak }));
    };

    const changeOpenAiApiKey = () => {
        if (isOpenAiApiKeyChanged) {
            if (!!openAiApiKey?.length) {
                dispatch(updateOptions({ ...options, openAiApiKey }));
                openai.useApiKey(openAiApiKey);
            } else {
                dispatch(updateOptions({ ...options, openAiApiKey: undefined }));
                openai.useApiKey(undefined);
            }
        }
    };

    return (
        <div className="card mb-4">
            <div className="card-header">
                Options
            </div>
            <div className="card-body">
                <div className="form-check form-switch">
                    <input id="generateImages" type="checkbox" className="form-check-input" role="switch" checked={options.generateImages} onChange={toggleGenerateImages} />
                    <label htmlFor="generateImages" className="form-check-label">Generate images</label>
                </div>
                <div className="form-check form-switch">
                    <input id="generateSpeech" type="checkbox" className="form-check-input" role="switch" checked={options.generateSpeech} onChange={toggleGenerateSpeech} />
                    <label htmlFor="generateSpeech" className="form-check-label">Generate TTS speech</label>
                </div>
                <div className="form-check form-switch">
                    <input id="autoSpeak" type="checkbox" className="form-check-input" role="switch" checked={options.autoSpeak} onChange={toggleAutoSpeak} />
                    <label htmlFor="autoSpeak" className="form-check-label">Read story using TTS automatically</label>
                </div>
                <div className="input-group mt-2">
                    <span className="input-group-text">OpenAI API Key</span>
                    <input id="openAiApiKey" type="text" className="form-control" value={openAiApiKey} onChange={({target}) => setOpenAiApiKey(target.value)} />
                    <button type="button" className="btn btn-primary" disabled={!isOpenAiApiKeyChanged} onClick={changeOpenAiApiKey}><i className="bi-floppy"></i></button>
                </div>
            </div>
        </div>
    );
}