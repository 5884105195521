export interface LocalStorageRepresentable<T> {
    read: () => T | null;
    write: (value: T) => void;
    clear: () => void;
}

export function localStorageString(key: string, options?: { storage?: Storage }): LocalStorageRepresentable<string> {
    const storage = options?.storage ?? localStorage;

    const read = () => storage.getItem(key);

    const write = (value: string) => storage.setItem(key, value);

    const clear = () => storage.removeItem(key);

    return { read, write, clear };
}

export function localStorageStringArray(key: string, options?: { storage?: Storage, separator?: string }): LocalStorageRepresentable<Array<string>> {
    const separator = options?.separator ?? '\t';
    const str = localStorageString(key, options);

    const read = () => {
        const value = str.read();
        if (!value?.length) {
            return [];
        } else {
            return value.split(separator);
        }
    };

    const write = (values: string[]) => str.write(values.join(separator));

    const clear = () => str.clear();

    return { read, write, clear };
}

export function localStorageJSON<T>(key: string, options?: { storage?: Storage }): LocalStorageRepresentable<T> {
    const str = localStorageString(key, options);

    const read = () => {
        const json = str.read();
        if (!json?.length) {
            return null;
        } else {
            return JSON.parse(json);
        }
    };

    const write = (value: T) => {
        const json = JSON.stringify(value);
        str.write(json);
    };

    const clear = () => str.clear();

    return { read, write, clear };
}

export function localStorageJSONArray<T>(key: string, options?: { storage?: Storage, separator?: string }): LocalStorageRepresentable<Array<T>> {
    const str = localStorageStringArray(key, options);

    const read = () => {
        const jsonArray = str.read() ?? [];
        if (!jsonArray?.length) {
            return [];
        } else {
            return jsonArray.map(json => JSON.parse(json));
        }
    };

    const write = (values: T[]) => {
        const jsonArray = values.map(value => JSON.stringify(value));
        str.write(jsonArray);
    };

    const clear = () => str.clear();

    return { read, write, clear };
}
