import { Scenario, allScenarios } from "../types/Scenario";
import PageHeader from "./PageHeader";

import ScenarioCard from "./ScenarioCard";

export default function ScenarioSelection() {
    const scenarios = allScenarios();
    const toCard = (scenario: Scenario, index: number) => {
        return (
            <div className="col" key={index}>
                <ScenarioCard scenario={scenario} />
            </div>
        );
    };

    return (
        <div>
            <PageHeader title="Storytime" />
            <div className="mt-4 mb-4">
                <div className="text-muted text-center mb-4">Select a story...</div>
                <div className="row row-cols-4 g-4 justify-content-center">{scenarios.map(toCard)}</div>
            </div>
        </div>
    );
}
