import { pushUserInput } from "../redux/StorySlice";
import { useAppDispatch } from "../redux/store";

export default function ChoiceButton({ choice }: { choice: string }) {
    const dispatch = useAppDispatch();

    const select = () => {
        const selection = getSelection(choice);
        dispatch(pushUserInput(selection));
    };

    return <button type="button" onClick={select} className="choice btn btn-link link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover font-monospace text-start p-0">- {choice}</button>
}

function getSelection(text: string): string {
    const index = text.indexOf('.');
    if (index !== -1) {
        return text.substring(0, index);
    } else {
        return text;
    }
}