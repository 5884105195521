import { FormEvent, useState } from "react";
import { pushUserInput, selectStoryItems } from "../redux/StorySlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

export default function UserInputTextfield() {
    const dispatch = useAppDispatch();
    const story = useAppSelector(selectStoryItems);
    const [text, setText] = useState("");

    const isGenerating = story
        .map(next => next.status)
        .filter(next => next.content === 'generating' || next.image === 'generating' || next.speech === 'generating')
        .length > 0;
    
    const isSendingEnabled = !!text?.length && !isGenerating;

    const send = (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (isSendingEnabled) {
            dispatch(pushUserInput(text));
            setText("");
        }

        return false;
    };

    return (
        <form className="user-input form" noValidate onSubmit={send}>
            <div className="input-group">
                <input type="text" className="form-control" value={text} onChange={({target}) => setText(target.value)} />
                <button type="submit" className="btn btn-primary" disabled={!isSendingEnabled}><i className="bi-send"></i></button>
            </div>
        </form>
    );
}