import { deleteRecentStory, openRecentStory, selectRecentStories } from "../redux/StorySlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { RecentStory } from "../types/RecentStory";

export default function RecentStoriesList() {
    const dispatch = useAppDispatch();
    const recentStories = useAppSelector(selectRecentStories);

    const toListItem = (recentStory: RecentStory, index: number) => {
        const creation = new Date(recentStory.creation);
        const date = creation.toLocaleDateString();
        const time = creation.toLocaleTimeString();

        const open = () => dispatch(openRecentStory(recentStory));
        const remove = () => dispatch(deleteRecentStory(recentStory));

        return (
            <div key={index} className="list-group-item d-flex w-100 justify-content-between align-items-center">
                <div>
                    <div>{recentStory.scenario.name}</div>
                    <div className="text-muted"><small>{date} {time}</small></div>
                </div>
                <div>
                    <button type="button" className="btn btn-link text-danger" onClick={remove}><i title="Delete Story" className="bi-trash"></i></button>
                    <button type="button" className="btn btn-link" onClick={open}><i title="Open Story" className="bi-book"></i></button>
                </div>
            </div>
        );
    };

    return (
        <div className="card mb-4">
            <div className="card-header">Recent Stories</div>
            <ul className="list-group list-group-flush">
                {recentStories.length === 0 && <li className="list-group-item text-center text-muted">No recent stories recorded.</li>}
                {recentStories.map(toListItem)}
            </ul>
        </div>
    );
}
