import { useOpenAI } from "../openai/OpenAIContext";
import { updateStoryItem, updateStoryStatus } from "../redux/StorySlice";
import { useAppDispatch } from "../redux/store";
import { StoryItem, image } from "../types/StoryItem";

export default function StoryImageBlock({ story }: { story: StoryItem }) {
    const url = story.imageURL;
    const description = story.content?.summary ?? 'Description of this story element';

    if (!url?.length) {
        if (story.status.image === 'failed' || !!story.imageError?.length) {
            return <FailedStoryImageBlock story={story} />;
        } else {
            return null;
        }
    }

    return (
        <div className="p-0">
            <img src={url} alt={description} className="img-fluid rounded-start story-image" />
        </div>
    );
}

function FailedStoryImageBlock({ story }: { story: StoryItem }) {
    const { imageError } = story;
    const dispatch = useAppDispatch();
    const openai = useOpenAI();

    const retry = () => {
        const done = (item: StoryItem | null) => {
            if (item) {
                dispatch(updateStoryItem(item));
                dispatch(updateStoryStatus({ uuid: item.uuid, generator: 'image', status: 'completed' }));
            }
        };

        const error = (error: Error) => {
            dispatch(updateStoryItem({ ...story, imageError: error.message }));
            dispatch(updateStoryStatus({ uuid: story.uuid, generator: 'image', status: 'failed' }));
        };

        dispatch(updateStoryStatus({ uuid: story.uuid, generator: 'image', status: 'pending' }));

        image(openai.api, story).then(done).catch(error);
    };

    return (
        <div className="text-center p-4">
            <div className="text-warning font-monospace">Oopsi, something went wrong.</div>
            {!!imageError?.length && <div className="text-warning font-monospace">{imageError}</div>}
            <button type="button" className="btn btn-primary" onClick={retry}>Retry</button>
        </div>
    );
}
