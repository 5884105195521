import { pushUserInput } from "../redux/StorySlice";
import { useAppDispatch } from "../redux/store";

export default function StartButton() {
    const dispatch = useAppDispatch();

    const start = () => dispatch(pushUserInput('start'));

    return <button type="button" className="btn btn-primary btn-lg ps-4 pe-4" onClick={start}><i className="bi-play-fill"></i> Start</button>;
}
