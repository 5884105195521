import { useEffect } from "react";
import { GeneratorStatus } from "../types/GeneratorStatus";
import { StoryItem } from "../types/StoryItem";
import { useOpenAI } from "../openai/OpenAIContext";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { selectScenario, selectStoryItems, selectStoryOptions, updateStoryItem, updateStoryStatus } from "../redux/StorySlice";

type Kind = 'Content' | 'Image' | 'TTS';

export default function StoryStatusIndicator({ story }: { story: StoryItem }) {
    const { content, image, speech } = story.status;
    const openai = useOpenAI();
    const dispatch = useAppDispatch();
    const allItems = useAppSelector(selectStoryItems);
    const scenario = useAppSelector(selectScenario);
    const options = useAppSelector(selectStoryOptions);

    useEffect(() => {
        if (!story.content?.story?.length && story.status.content === undefined && !!scenario?.premise?.length) {
            const done = (item: StoryItem) => {
                const generating = item.status.content === 'generating' || item.status.image === 'generating' || item.status.speech === 'generating';
                const hasContent = !!item.content?.story?.length;
                const hasContentError = !!item.contentError?.length;

                if (!generating) {
                    if (hasContent) {
                        dispatch(updateStoryItem(item));
                    } else if (hasContentError) {
                        dispatch(updateStoryItem({ ...item, status: { content: 'failed' } }));
                    }
                }
            };

            openai.generate({
                storyItem: story,
                story: allItems,
                scenario,
                options,
                updateStoryItemStatus: (uuid, generator, status) => {
                    dispatch(updateStoryStatus({ uuid, generator, status }));
                },
            }).then(done).catch(console.error);
        }
    }, [story, allItems, dispatch, openai, options, scenario]);

    const noContent = content === undefined || content === 'completed' || content === 'failed';
    const noImage = image === undefined || image === 'completed' || image === 'failed';
    const noSpeech = speech === undefined || speech === 'completed' || speech === 'failed';
    if (noContent && noImage && noSpeech) {
        return null;
    }

    return (
        <div className="text-center d-flex w-100 justify-content-center align-items-center">
            <Indicator kind="Content" status={content} />
            <Indicator kind="Image" status={image} />
            <Indicator kind="TTS" status={speech} />
        </div>
    );
}

function Indicator({ kind, status }: { kind: Kind, status?: GeneratorStatus }) {
    if (status === 'pending') {
        return <Pending kind={kind} />;
    } else if (status === 'generating') {
        return <Generating kind={kind} />;
    } else if (status === 'failed') {
        return <Failed kind={kind} />;
    } else if (status === 'completed') {
        return <Completed kind={kind} />;
    } else {
        return null;
    }
}

function Pending({ kind }: { kind: Kind }) {
    return (
        <div className="placeholder-glow">
            <div className="rounded-circle text-center text-muted bg-secondary m-4 fs-1 placeholder" title="Waiting..." style={{ width: '4rem', height: '4rem' }}>
                <i className={icon(kind)}></i>
            </div>
        </div>
    );
}

function Generating({ kind }: { kind: Kind }) {
    return (
        <div className="placeholder-glow">
            <div className="rounded-circle text-center bg-primary m-4 fs-1 placeholder" title="Generating..." style={{ width: '4rem', height: '4rem' }}>
                <i className={icon(kind)}></i>
            </div>
        </div>
    );
}

function Failed({ kind }: { kind: Kind }) {
    return (
        <div>
            <div className="rounded-circle text-center bg-danger m-4 fs-1" title="Failed" style={{ width: '4rem', height: '4rem' }}>
                <i className={icon(kind)}></i>
            </div>
        </div>
    );
}

function Completed({ kind }: { kind: Kind }) {
    return (
        <div>
            <div className="rounded-circle text-center bg-success m-4 fs-1" title="Completed" style={{ width: '4rem', height: '4rem' }}>
                <i className={icon(kind)}></i>
            </div>
        </div>
    );
}

function icon(kind: Kind): string {
    switch (kind) {
        case 'Content': return "bi-pencil";
        case 'Image': return "bi-brush";
        case 'TTS': return "bi-mic";
    }
}