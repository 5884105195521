import { Scenario } from "../types/Scenario";

const premise = `This is a text adventure game called "Xmas in Valley Lodge". The story begins in a lodge 
in the middle of the woods on a snowy night on christmas eve. Our character, Torgo, is preparing for a big 
christmas party. We have invited all our friends to that party. What will the big mystery of our story be? 

Here is a list of all guests who are invited to the christmas party:

- Steve, a squirrel.
- Richard Shooter, the mayor of Dickshooter.
- Pepsiman, a shady character involved in a conspiracy.
- Dianalee, an adventurous girl looking for the Orb of Destiny.
- Trevor Snodgrass, Dianalee's biology teacher, who sent Dianalee to look for the Orb of Destiny.
- Aldo, an Italian plumber with a moustache and a red baseball cap.
- Eagleman, an insurance salesman, with really big eyes.
- Crazy Bruce, a drunkard who sells liquor.
- Delbert, nobody has ever heard of him before.
- Debbie, a little ghost girl.
- Ecco, the god of dolphins and our nemesis.
- Gary, a cook who specializes in barbeque.
- A giant moth.`;

const scenario: Scenario = {
    name: 'Christmas in Valley Lodge',
    description: `A christmas adventure in Valley Lodge`,
    imageName: 'ValleyLodgeXmas.png',
    premise,
    voice: 'fable',
};

export default scenario;