import "./index.css";
import { OpenAIContext, defaultOpenAI } from "./openai/OpenAIContext";
import { store, useAppSelector } from "./redux/store";
import { Provider } from "react-redux";
import { selectScenario } from "./redux/StorySlice";

import ScenarioSelection from "./components/ScenarioSelection";
import ScenarioView from "./components/ScenarioView";

export default function App() {
	const openai = defaultOpenAI();

	return (
		<OpenAIContext.Provider value={openai}>
			<Provider store={store}>
				<Content />
			</Provider>
		</OpenAIContext.Provider>
	);
}

function Content() {
	const scenario = useAppSelector(selectScenario);

	return (
		<div className="container">
			{scenario === null && <ScenarioSelection />}
			{scenario !== null && <ScenarioView scenario={scenario} />}
		</div>
	);
}
