import { useState } from "react";
import OptionsPanel from "./OptionsPanel";
import RecentStoriesList from "./RecentStoriesList";
import { useAppSelector } from "../redux/store";
import { selectStoryItems } from "../redux/StorySlice";
import DownloadStoryButton from "./DownloadStoryButton";
import BigModeButton from "./BigModeButton";

export default function PageHeader({ title }: { title: string }) {
    const items = useAppSelector(selectStoryItems);
    const [isOptionsShown, setOptionsShown] = useState(false);
    const [isRecentStoriesShown, setRecentStoriesShown] = useState(false);

    const toggleOptionsShown = () => setOptionsShown(!isOptionsShown);
    const toggleRecentStoriesShown = () => setRecentStoriesShown(!isRecentStoriesShown);

    return (
        <div>
            <h1 className="d-flex w-100 justify-content-between align-items-center">
                <div>{title}</div>
                <div>
                    {items.length > 0 && <DownloadStoryButton />}
                    <BigModeButton />
                    <button type="button" className="btn btn-link" title="Recent Stories" onClick={toggleRecentStoriesShown}><i className={isRecentStoriesShown ? "bi-folder2-open" : "bi-folder"}></i></button>
                    <button type="button" className="btn btn-link" title="Options" onClick={toggleOptionsShown}><i className="bi-gear"></i></button>
                </div>
            </h1>
            {isOptionsShown && <OptionsPanel />}
            {isRecentStoriesShown && <RecentStoriesList />}
        </div>
    );
}
