import { selectRecentStories, selectStoryItems } from "../redux/StorySlice";
import { useAppSelector } from "../redux/store";

export default function DownloadStoryButton() {
    const items = useAppSelector(selectStoryItems);
    const uuids = items.map(item => item.uuid).join('::');
    const stories = useAppSelector(selectRecentStories);
    const story = stories.find(next => next.items.map(item => item.uuid).join('::') === uuids);

    const download = () => {
        if (story) {
            const json = JSON.stringify(story);
            const data = `data:application/json;charset=UTF-8,${encodeURIComponent(json)}`;
            const filename = `${story.scenario.name} - ${story.creation}.json`;

            const anchor = document.createElement('a');
            anchor.setAttribute('href', data);
            anchor.setAttribute('download', filename);
            document.body.appendChild(anchor);

            anchor.click();
            anchor.remove();
        }
    };

    return <button type="button" className="btn btn-link" title="Download Story" onClick={download}><i className="bi-download"></i></button>;
}