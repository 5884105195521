import { retryLastUserInput } from "../redux/StorySlice";
import { useAppDispatch } from "../redux/store";
import { StoryItem } from "../types/StoryItem";

import ChoiceButton from "./ChoiceButton";
import SpeakButton from "./SpeakButton";

export default function StoryTextBlock({ story, current }: { story: StoryItem, current?: boolean }) {
    const { content } = story;
    const paragraphs = (content?.story ?? '').split('\n\n');
    const actions = content?.actions ?? [];

    if (!content?.story?.length) {
        if (story.status.content === 'failed' || !!story.contentError?.length) {
            return <FailedStoryTextBlock story={story} current={current} />;
        } else {
            return null;
        }
    }

    const renderCurrentChoice = (choice: string, index: number) => (
        <div key={`choice_${index}`} className="text-start">
            <ChoiceButton choice={choice} />
        </div>
    );

    const renderLine = (paragraphIndex: number) => (line: string, lineIndex: number) => (
        <div key={`paragraph_${paragraphIndex}_line_${lineIndex}`}>{line}</div>
    );

    const renderChoice = (choice: string, index: number) => {
        if (current) {
            return renderCurrentChoice(choice, index);
        } else {
            return renderLine(100000)(`- ${choice}`, index);
        }
    };

    const renderParagraph = (paragraph: string, index: number) => {
        const lines = paragraph.split('\n');
        return <div key={index} className="mb-3">{lines.map(renderLine(index))}</div>;
    };

    return (
        <div className="story-text p-2">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="chosen-option font-monospace text-info mb-3">{story.userInput}</div>
                <SpeakButton story={story} />
            </div>
            <div className="font-monospace">{paragraphs.map(renderParagraph)}</div>
            <div className="font-monospace">{actions.map(renderChoice)}</div>
        </div>
    );
}

function FailedStoryTextBlock({ story, current }: { story: StoryItem, current?: boolean }) {
    const { contentError } = story;
    const dispatch = useAppDispatch();

    const retry = () => {
        if (!!current) {
            dispatch(retryLastUserInput());
        }
    };

    return (
        <div className="story-text p-2">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="chosen-option font-monospace text-info mb-3">{story.userInput}</div>
            </div>
            <div className="text-warning font-monospace">Oopsi, something went wrong.</div>
            {!!contentError?.length && <div className="text-warning font-monospace">{contentError}</div>}
            {!!current && <button type="button" className="btn btn-primary" onClick={retry} disabled={!current}>Retry</button>}
        </div>
    );
}
