import { startScenario } from "../redux/StorySlice";
import { useAppDispatch } from "../redux/store";
import { Scenario } from "../types/Scenario";

export default function ScenarioCard({ scenario }: { scenario: Scenario }) {
    const dispatch = useAppDispatch();

    const start = () => dispatch(startScenario(scenario.name));

    return (
        <div className="scenario card" onClick={start}>
            <img src={`images/${scenario.imageName}`} alt={scenario.name} className="card-img-top" />
            <div className="card-body">
                <h5 className="card-title">{scenario.name}</h5>
                <div className="card-text">{scenario.description}</div>
            </div>
        </div>
    );
}
