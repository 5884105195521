import OpenAI from "openai";
import { v4 as uuid4 } from "uuid";
import { StoryOptions } from "./StoryOptions";
import { StoryContent, parseStoryContent } from "./StoryContent";
import { GeneratorStatus, StoryItemStatus, UpdateGeneratorStatusFn } from "./GeneratorStatus";
import { localStorageJSON } from "./LocalStorageRepresentable";
import { SYSTEM, Scenario } from "./Scenario";

export interface StoryItem {
    uuid: string;
    userInput: string;
    content?: StoryContent | null;
    speechURL?: string | null;
    imageURL?: string | null;
    status: StoryItemStatus;
    contentError?: string;
    imageError?: string;
    speechError?: string;
}

export function makeStoryItem(userInput: string): StoryItem {
    const uuid = uuid4();
    const status: StoryItemStatus = {
        content: undefined,
        image: undefined,
        speech: undefined,
    };
    
    return { uuid, userInput, status };
}

const CONTENT_CACHE = localStorageJSON<{[key: string]: StoryContent}>('contentCache', { storage: sessionStorage });
const IMAGE_CACHE = localStorageJSON<{[key: string]: string}>('imageCache', { storage: sessionStorage });
const SPEECH_CACHE = localStorageJSON<{[key: string]: string}>('speechCache', { storage: sessionStorage });

class InternalCache {
    static readonly shared = new InternalCache();

    private contentCache: {[key: string]: StoryContent};
    private speechCache: {[key: string]: string};
    private imageCache: {[key: string]: string};

    private pendingContent = new Set<string>();
    private pendingImages = new Set<string>();
    private pendingSpeeches = new Set<string>();

    private constructor() {
        this.contentCache = CONTENT_CACHE.read() ?? {};
        this.imageCache = IMAGE_CACHE.read() ?? {};
        this.speechCache = SPEECH_CACHE.read() ?? {};
    }

    async content(uuid: string, generate: () => Promise<StoryContent | null>): Promise<StoryContent | null> {
        const cached = uuid in this.contentCache ? this.contentCache[uuid] : undefined;
        if (!!cached?.story?.length) {
            return cached;
        }

        if (this.pendingContent.has(uuid)) {
            return null;
        }

        try {
            this.pendingContent.add(uuid);
            const content = await generate();
            if (content === null) {
                return null;
            } else {
                this.contentCache[uuid] = content;
            }

            CONTENT_CACHE.write(this.contentCache);
    
            return content;
        } finally {
            this.pendingContent.delete(uuid);
        }
    }

    async image(uuid: string, generate: () => Promise<string | null>): Promise<string | null> {
        const cached = uuid in this.imageCache ? this.imageCache[uuid] : undefined;
        if (!!cached?.length) {
            return cached;
        }

        if (this.pendingImages.has(uuid)) {
            return null;
        }

        try {
            this.pendingImages.add(uuid);
            const url = await generate();
            if (url === null) {
                return null;
            } else {
                this.imageCache[uuid] = url;
            }

            IMAGE_CACHE.write(this.imageCache);

            return url;
        } finally {
            this.pendingImages.delete(uuid);
        }
    }

    async speech(uuid: string, generate: () => Promise<string>): Promise<string | null> {
        const cached = uuid in this.speechCache ? this.speechCache[uuid] : undefined;
        if (!!cached?.length) {
            return cached;
        }

        if (this.pendingSpeeches.has(uuid)) {
            return null;
        }

        try {
            this.pendingSpeeches.add(uuid);
            const url = await generate();
            this.speechCache[uuid] = url;

            SPEECH_CACHE.write(this.speechCache);

            return url;
        } finally {
            this.pendingSpeeches.delete(uuid);
        }
    }
}

function generateContent(openai: OpenAI, uuid: string, userInput: string, premise: string, story: Array<StoryItem>): () => Promise<StoryContent | null> {
    return async () => {
        console.log(`Generating story content for ${uuid} with user input "${userInput}"`);
        const messages: Array<OpenAI.ChatCompletionMessageParam> = [];

        // Collect previous story items.
        const previousContent = story
            .filter(next => !!next.content?.story?.length)
            .slice(-4);
        
        // First add the story premise.
        messages.push({ role: 'system', content: premise });

        // Next, add the previous story items.
        previousContent.forEach(next => {
            messages.push({ role: 'user', content: `The next action the player took was: ${next.userInput}` });
            messages.push({ role: 'assistant', content: next.content?.story ?? '' });
        });

        // Next, add the current user input.
        messages.push({ role: 'user', content: userInput });

        // Finally add the system message.
        messages.push({ role: 'system', content: SYSTEM });

        const completions = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo',
            temperature: 1,
            messages,
        });

        if (completions.choices.length < 1 || !completions.choices[0].message?.content?.length) {
            return null;
        } else {
            return parseStoryContent(completions.choices[0].message.content);
        }
    };
}

function generateImage(openai: OpenAI, uuid: string, description: string): () => Promise<string | null> {
    return async () => {
        console.log(`Generating image for ${uuid}`);
        const prompt = `Make a cute pixel graphic: """${description}"""`;

        const images = await openai.images.generate({
            model: 'dall-e-3',
            quality: 'standard',
            size: '1024x1024',
            n: 1,
            prompt,
        });

        if (images.data.length < 1 || !images.data[0].url?.length) {
            return null;
        } else {
            return images.data[0].url;
        }
    };
}

function generateSpeech(openai: OpenAI, uuid: string, input: string, voice?: string): () => Promise<string> {
    return async () => {
        console.log(`Generating TTS for ${uuid}`);

        const speech = await openai.audio.speech.create({
            model: 'tts-1',
            voice: (voice as "nova" | "alloy" | "echo" | "fable" | "onyx" | "shimmer" | undefined) ?? 'nova',
            response_format: 'mp3',
            input,
        });

        const blob = await speech.blob();
        const url = URL.createObjectURL(blob);

        return url;
    };
}

export async function content(openai: OpenAI, storyItem: StoryItem, premise: string, story: Array<StoryItem>): Promise<StoryItem | null> {
    if (!!storyItem.content?.story?.length) {
        return storyItem;
    }

    const { uuid, userInput } = storyItem;
    const cache = InternalCache.shared;
    const content = await cache.content(uuid, generateContent(openai, uuid, userInput, premise, story));
    if (content === null) {
        return null;
    }

    return { ...storyItem, content };
}

export async function image(openai: OpenAI, storyItem: StoryItem): Promise<StoryItem | null> {
    if (!!storyItem.imageURL?.length || !storyItem.content?.story?.length) {
        return storyItem;
    }

    const { uuid, content } = storyItem;
    const cache = InternalCache.shared;
    const description = content.summary ?? content.story;
    const imageURL = await cache.image(uuid, generateImage(openai, uuid, description));
    if (imageURL === null) {
        return null;
    }

    return { ...storyItem, imageURL };
}

export async function speech(openai: OpenAI, storyItem: StoryItem, voice?: string): Promise<StoryItem | null> {
    if (!!storyItem.speechURL?.length || !storyItem.content?.story?.length) {
        return storyItem;
    }

    const { uuid, content } = storyItem;
    const cache = InternalCache.shared;
    const speechURL = await cache.speech(uuid, generateSpeech(openai, uuid, content.story, voice));
    if (speechURL === null) {
        return null;
    }

    return { ...storyItem, speechURL };
}

interface StoryItemGenerationProps {
    openai: OpenAI;
    storyItem: StoryItem;
    scenario: Scenario;
    story: Array<StoryItem>;
    options: StoryOptions;
    updateContentStatus?: UpdateGeneratorStatusFn;
    updateImageStatus?: UpdateGeneratorStatusFn;
    updateSpeechStatus?: UpdateGeneratorStatusFn;
}

export async function storyItem({ openai, storyItem, scenario, story, options, updateContentStatus, updateImageStatus, updateSpeechStatus }: StoryItemGenerationProps): Promise<StoryItem> {
    let contentResult = storyItem;
    const needsContent = !storyItem.content?.story?.length;
    const needsImage = !storyItem.imageURL?.length && options.generateImages;
    const needsSpeech = !storyItem.speechURL?.length && options.generateSpeech;

    const contentStatus = (status: GeneratorStatus) => {
        if (needsContent && updateContentStatus) {
            updateContentStatus(status);
        }
    };

    const imageStatus = (status: GeneratorStatus) => {
        if (needsImage && updateImageStatus) {
            updateImageStatus(status);
        }
    };

    const speechStatus = (status: GeneratorStatus) => {
        if (needsSpeech && updateSpeechStatus) {
            updateSpeechStatus(status);
        }
    };

    contentStatus('pending');
    imageStatus('pending');
    speechStatus('pending');

    if (needsContent) {
        try {
            contentStatus('generating');
            const result = await content(openai, contentResult, scenario.premise, story);
            if (result !== null) {
                contentResult = result;
                contentStatus('completed');
            }
        } catch (err) {
            console.error(err);
            const error = typeof err === 'string' ? null : err as Error;
            contentStatus('failed');
            imageStatus('failed');
            speechStatus('failed');
            return { ...storyItem, contentError: error?.message ?? `${err}` };
        }
    }

    let promisedImage: Promise<StoryItem> = Promise.resolve(contentResult);
    if (needsImage) {
        if (!!contentResult?.content?.story?.length) {
            promisedImage = new Promise((resolve, reject) => {
                imageStatus('generating');
                image(openai, contentResult)
                    .then(ret => {
                        if (!!ret?.imageURL?.length) {
                            imageStatus('completed');
                            resolve(ret);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        const error = typeof err === 'string' ? null : err as Error;
                        imageStatus('failed');
                        resolve({ ...contentResult, imageError: error?.message ?? `${err}` });
                    });
            });
        } else {
            imageStatus('pending');
        }
    }

    let promisedSpeech: Promise<StoryItem> = Promise.resolve(contentResult);
    if (needsSpeech) {
        if (!!contentResult?.content?.story?.length) {
            promisedSpeech = new Promise((resolve, reject) => {
                speechStatus('generating');
                speech(openai, contentResult, scenario.voice)
                    .then(ret => {
                        if (!!ret?.speechURL?.length) {
                            speechStatus('completed');
                            resolve(ret);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        const error = typeof err === 'string' ? null : err as Error;
                        speechStatus('failed');
                        resolve({ ...contentResult, speechError: error?.message ?? `${err}` });
                    });
            });
        } else {
            speechStatus('pending');
        }
    }

    const [imageResult, speechResult] = await Promise.all([promisedImage, promisedSpeech]);
    
    return {
        ...contentResult,
        imageURL: imageResult.imageURL,
        speechURL: speechResult.speechURL,
        imageError: imageResult.imageError,
        speechError: speechResult.speechError
    };
}
