import valleyLodge from "../scenarios/ValleyLodge";
import valleyLodgeXmas from "../scenarios/ValleyLodgeXmas";

export type ScenarioName = 'Valley Lodge' | 'Christmas in Valley Lodge';
export const ALL_SCENARIO_NAMES: Array<ScenarioName> = ['Valley Lodge', 'Christmas in Valley Lodge'];

export interface Scenario {
    name: ScenarioName;
    description: string;
    imageName: string;
    premise: string;
    voice?: string;
}

export function makeScenario(name: ScenarioName): Scenario {
    switch (name) {
        case 'Valley Lodge': return {...valleyLodge};
        case 'Christmas in Valley Lodge': return {...valleyLodgeXmas};
    }
}

export function allScenarios(): Array<Scenario> {
    return ALL_SCENARIO_NAMES.map(makeScenario);
}

export const SYSTEM = `
Generate an adventure game and provide output in JSON format, containing the following fields:

- "story": the generated story; make sure there is a high chance of at least one character to interact with.
- "actions": an array of actions the player can choose from, each action is a string; make sure there are at least two actions to choose from!
- "summary": a short description of what can be seen in the scene.

Here is an example how the output may look like, fill in the contents of the fields accordingly:

{
    "story": "You find yourself in a poorly lit room all by yourself. As your eyes adjust to the darkness, you start to make out a few things you can see: a teapot, a door to your left and a bed in front of you.",
    "actions": ["Pick up teapot", "Open door", "Examine bed"],
    "summary": "A dark room with a teapot, a bed and a door to the left."
}
`;
