import { selectStoryItems } from "../redux/StorySlice";
import { useAppSelector } from "../redux/store";
import { Scenario } from "../types/Scenario";

import StartButton from "./StartButton";
import StoryElementsList from "./StoryElementsList";
import UserInputTextfield from "./UserInputTextfield";
import PageHeader from "./PageHeader";

export default function ScenarioView({ scenario }: { scenario: Scenario }) {
	const story = useAppSelector(selectStoryItems);

	return (
		<div>
			<PageHeader title={scenario.name} />
			<StoryElementsList />
			{story.length === 0 && <div className="text-center mb-4"><StartButton /></div>}
			<UserInputTextfield />
		</div>
	);
}
