export interface StoryContent {
    story: string;
    actions: Array<string>;
    summary?: string;
}

export function parseStoryContent(text: string): StoryContent | null {
    return generateStoryContentFromJSON(text)
        ?? generateStoryContentFromBrokenJSON(text)
        ?? generateStoryContentFromText(text);
}

function generateStoryContentFromJSON(json: string): StoryContent | null {
    if (!json?.length) {
        return null;
    }

    try {
        const obj = JSON.parse(json);
        if (!('story' in obj && typeof obj.story === 'string')) {
            return null;
        }
        if (!('actions' in obj && typeof obj.actions === 'object' && 'length' in obj.actions && typeof obj.actions.length === 'number' && obj.actions.length > 0)) {
            return null;
        }

        const story = obj.story as string;
        const actions = obj.actions as string[];
        const summary = ('summary' in obj && typeof obj.summary === 'string') ? obj.summary : undefined;

        return { story, actions, summary };
    } catch {
        return null;
    }
}

function generateStoryContentFromBrokenJSON(text: string): StoryContent | null {
    const storyRegex = /"story": "(.+?)"/g;
    const actionsRegex = /"actions": \[(.+?)\]/g;
    const summaryRegex = /"summary": "(.+?)"/g;

    const storyMatch = storyRegex.exec(text);
    const actionsMatch = actionsRegex.exec(text);
    const summaryMatch = summaryRegex.exec(text);

    if (!storyMatch?.length || storyMatch.length < 2) {
        return null;
    }

    const story = storyMatch[1];
    if (!story?.length) {
        return null;
    }

    const actionsString = (!!actionsMatch?.length && actionsMatch.length > 1) ? actionsMatch[1] : undefined;
    const actions: Array<string> = [];
    if (!!actionsString?.length) {
        try {
            const actionsArray = JSON.parse(`[${actionsString}]`) as string[];
            actionsArray.forEach(a => actions.push(a));
        } catch {
        }
    }
    if (!actions.length) {
        return null;
    }

    const summary = (!!summaryMatch?.length && summaryMatch.length > 1) ? summaryMatch[1] : undefined;

    return { story, actions, summary };
}

function generateStoryContentFromText(text: string): StoryContent | null {
    if (!text?.length) {
        return null;
    }

    const isOrderedChoice = (line: string) => /^\d+?\. \+$/.test(line);
    const isUnorderedChoice = (line: string) => /^\- .+$/.test(line);
    const isChoice = (line: string) => isOrderedChoice(line) || isUnorderedChoice(line);

    const actions: Array<string> = [];
    const storyParagraphs: Array<string> = [];

    const paragraphs = text.split('\n\n');
    for (const paragraph of paragraphs) {
        const lines = paragraph.split('\n');
        const currentActions: Array<string> = [];
        for (const line of lines) {
            if (isChoice(line)) {
                currentActions.push(line);
            }
        }

        if (currentActions.length > 1) {
            currentActions.forEach(action => actions.push(action));
        } else {
            storyParagraphs.push(paragraph);
        }
    }

    const story = storyParagraphs.join('\n\n');

    return { story, actions };
}